import React, { Component } from 'react'
import PageHeaderWrapper from '../../../components/PageHeaderWrapper'
import {
  Table,
  Form,
  Input,
  DatePicker,
  Button,
  Card,
  Icon,
  Row,
  Col, Tooltip, Popconfirm
} from 'antd'
import { notification } from 'antd/lib/index'
import { hideLoader, showLoader } from '../../../modules/actions'
import Request from '../../../request'
import { connect } from 'react-redux'
import styles from './styles.less'
import { apiUrl } from '../../../settings'

@Form.create()
class AddArticle extends Component {

  handleChange = v => {
    if (v) {
      this.setState({ files: v })
    }
  }

  constructor(props) {
    super(props)
    this.state = {
      files: [],
      dataSource: []
    }

  }

  async handleSubmit() {
    const { dispatch } = this.props
    if (!this.state.files || (this.state.files && !this.state.files.length)) {
      notification.error({
        description: 'Please choose file'
      })
      return
    }

    dispatch(showLoader())
    let formData = new FormData()
    formData.append('uploadFile', this.state.files[0])
    let x = await Request.uploadFile(formData)

    dispatch(hideLoader())

    if (!x.error) {
      notification.success({
        message: x.message
      })

      this.setState({
        files: []
      })
      // this.getUploadsList()
      window.location.reload()
      this.fileInput.value = null
    } else {
      notification.error({
        message: 'Error Saving',
        description: x.message
      })
    }
  }

  componentDidMount() {
    this.getUploadsList()
  }

  async getUploadsList() {
    let x = await Request.uploadsList()
    this.setState({ dataSource: x })
  }

  async deleteUploadFile(id) {
    let x = await Request.deleteUploads({ _id: id })
    if (x.success) {
      notification.success({
        message: x.message
      })
      this.getUploadsList()
    } else {
      notification.error({
        message: 'Error Saving',
        description: x.message
      })
    }
  }

  render() {
    const { dataSource } = this.state
    const columns = [
      {
        title: 'Name',
        dataIndex: 'name',
        key: 'name'
      },
      {
        title: 'Image',
        dataIndex: 'path',
        key: 'path',
        render: (item) => {
          return (
            <img src={`${apiUrl}${item}`} style={{ height: 100 }}/>
          )
        }
      },
      {
        title: 'Action',
        dataIndex: '_id',
        key: '_id',
        render: (item) => (
          <React.Fragment>
            <Tooltip title="Delete Image ">
              <Popconfirm
                title="Are you sure delete this Image?"
                onConfirm={() => {
                  this.deleteUploadFile(item)
                }}
                onCancel={() => {
                  console.log()
                }}
                okText="Yes"
                cancelText="No">
                <Button loading={this.state && this.state.deleteLoading}
                        className={styles.btn}
                        size={'small'}
                        style={{ marginBottom: 6 }}
                        shape="circle"
                        icon="delete"
                />
              </Popconfirm>
            </Tooltip>


          </React.Fragment>
        )
      }
    ]
    return (
      <PageHeaderWrapper
        title={'Upload Image'}>

        <Card bordered={true}>
          <Form hideRequiredMark style={{ marginTop: 8 }}>
            <Row>
              <Col span={8}><h3>Choose File</h3></Col>
              <Col span={10}>
                <input type={'file'} className={styles.customInputFile} id={'uploadFile'}
                       ref={(input) => {
                         this.fileInput = input
                       }}
                       onChange={(value) => this.handleChange(value.target.files)}/>
              </Col>
            </Row>
            <br/>
            <Row>
              <Col span={8}></Col>
              <Col span={10}>
                <Button type="primary" htmlType="submit" loading={this.props.loading}
                        className={styles.submitBtn}
                        onClick={() => this.handleSubmit()}>
                  UPLOAD
                </Button>
              </Col>
            </Row>

            <Row style={{ marginTop: 30 }}>
              <Col>
                <Table dataSource={dataSource} columns={columns}/>
              </Col>
            </Row>


          </Form>
        </Card>

      </PageHeaderWrapper>
    )
  }
}

const mapStateToProps = ({ global, router }) => ({
  loading: global.buttonLoading,
  categories: global.categories,
  pathname: router.location.pathname

})
const mapDispatchToProps = dispatch => {
  return {
    dispatch
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddArticle)
