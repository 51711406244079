import React, {Component} from 'react'
import PageHeaderWrapper from '../../../components/PageHeaderWrapper'
//import {TableComp} from 'sz-react-utils'

import TableComp from '../../../components/_utils/table'
import moment from 'moment'
import {
    Table,
    Badge,
    Drawer,
    Popconfirm,
    Card,
    Tooltip,
    notification,
    Switch,
    Input, Button, Icon,
    Row,
    Col,
    Select
} from 'antd'
import Request from '../../../request'
import Color from 'color'
import _ from 'lodash'
import Highlighter from 'react-highlight-words'
import styles from './styles.less'
import S from 'string'
import {connect} from 'react-redux'
import memoizeOne from 'memoize-one'
import update from 'immutability-helper'
import {getPushPathWrapper} from '../../../routes'

const {Option} = Select

class AllArticles extends Component {

    articleDelete = async (_id) => {
        this.setState({deleteLoading: true})
        let x = await Request.deleteArticle({_id: _id})
        this.setState({deleteLoading: false})
        if (x && !x.error) {
            notification.success({
                message: 'Deleted Successfully'
            })
            this.table.current.reload()
        } else {
            notification.error({message: 'Try again'})
        }

    }
    deleteComment = async (_id) => {
        this.setState({deleteLoading: true})
        let x = await Request.deleteComment({_id: _id})
        this.setState({deleteLoading: false})
        if (x && !x.error) {
            notification.success({
                message: x.message
            })
            this.commentDetails(this.state.newId)
            this.table1.current.reload()
        } else {
            notification.error({message: 'Try again'})
        }

    }
    apiRequest = (params) => {
        return new Promise(async (resolve) => {
            if (this.state.isHighlight !== undefined) {
                params.isHighlight = this.state.isHighlight
            }

            if (!params.sortField) {
                params.sortField = 'publishDate'
                params.sortOrder = 'descend'
            }
            let data = await Request.getAllArticles({...params, regExFilters: ['category']})
            _.each(data.data, (item) => {
            })
            data.total = data.totalCount
            resolve(data)
        })
    }

    CategoryName = (val) => {
        if (val && this.state.catArr) {
            let catList = _.find(this.state.catArr, (item) => {
                return val[0] == item.id
            })
            if(catList){
                return catList.name
            }

        }

    }
    likeDetails = async (data1) => {
        this.setState({loading: true})
        let {data: likeData} = await Request.getLikeDetails(data1)
        this.setState({
            likeDemo: likeData
        })
        this.setState({loading: false})


    }
    shareDetails = async data1 => {
        this.setState({loading: true})
        let {data: shareData} = await Request.getShareDetails(data1)
        this.setState({
            shareDemo: shareData
        })
        this.setState({loading: false})


    }

    viewDetails = async data1 => {
        this.setState({loading: true})
        let {data: viewData} = await Request.getViewDetails(data1)
        this.setState({
            viewDemo: viewData
        })
        this.setState({loading: false})


    }
    commentDetails = async data1 => {
        this.setState({loading: true, newId: data1})
        let {data: commentData} = await Request.getCommentDetails(data1)
        this.setState({
            commentDemo: commentData
        })
        this.setState({loading: false})


    }
    showDrawer = () => {
        this.setState({
            visible: true
        })
    }


    showDrawer2 = () => {
        this.setState({
            visible2: true
        })
    }

    showDrawer1 = () => {
        this.setState({
            visible1: true
        })
    }
    showDrawer3 = () => {
        this.setState({
            visible3: true
        })
    }

    constructor(props) {
        super(props)
        this.state = {
            deleteLoading: false,
            isHighlight: 'All',
            visible3: false
        }
        this.table = React.createRef()
        this.table1 = React.createRef()

    }

    async componentDidMount() {
        let categoryArr = await Request.getCategoryList()

        this.setState({catArr: categoryArr})
    }

    async makeIsHighlightOrNot(id) {
        let resp = await Request.makeIsHighlightOrNot({_id: id})
        if (resp.success) {
            notification.success({
                message: resp.message
            })
            this.table.current.reload()
        } else {
            notification.error({
                message: resp.message
            })
        }
    }

    chooseBreaking(value) {
        this.setState({isHighlight: value}, () => {
            this.table.current.reload()
        })
    }

    render() {
        const {dispatch} = this.props
        const columns = [
            {
                title: 'Title',
                dataIndex: 'title',
                key: 'title',
                searchTextName: 'title'


            },
            {
                title: 'Category',
                dataIndex: 'category',
                key: 'categoryId',
                filters: this.state.catArr && this.state.catArr.map(x => ({value: x.id, text: x.name})),
                render: (val) => {
                    return this.CategoryName(val)
                }

            },

            {
                title: 'Publish Date',
                dataIndex: 'publishDate',
                sorter: true,
                key: 'publishDate',
                render: (val, record) => {
                    return <div>
                        {moment(val).format('YYYY-MMM-DD')}

                    </div>
                }
            },
            {
                title: 'Total Likes',
                dataIndex: 'totalLikes',
                key: 'totalLikes',
                render: (val, record) => {
                    return <div><a title={"View Likes List"} onClick={() => {
                        this.setState({
                            likeData: record
                        }, () => {
                            this.showDrawer()
                            this.likeDetails({_id: record._id})

                        })
                    }}
                    >{val}</a></div>
                }
            },
            {
                title: 'Total Comments',
                dataIndex: 'totalComments',
                key: 'totalComments',
                render: (val, record) => {
                    return <div><a title={"View Comments List"} onClick={() => {
                        this.setState({
                            commentData: record
                        }, () => {
                            this.showDrawer3()
                            this.setState({articleId: record._id})
                            this.commentDetails({_id: record._id})

                        })
                    }}>{val}</a></div>
                }
            },
            {
                title: 'Total Views',
                dataIndex: 'totalViews',
                key: 'totalViews',
                render: (val, record) => {
                    return <div><a title={"View Views List"} onClick={() => {
                        this.setState({
                            viewData: record
                        }, () => {
                            this.showDrawer2()
                            this.viewDetails({_id: record._id})

                        })
                    }}
                    >{val}</a></div>
                }
            },
            {
                title: 'Total Share',
                dataIndex: 'totalShares',
                key: 'totalShares',
                render: (val, record) => {
                    return <div><a title={"View Shares List"} onClick={() => {
                        this.setState({
                            shareData: record
                        }, () => {
                            this.showDrawer1()
                            this.shareDetails({_id: record._id})

                        })
                    }}>{val}</a></div>

                }
            },
            {
                title: 'Highlight',
                dataIndex: 'isHighlight',
                key: 'isHighlight',
                render: (item, record) => {
                    return (
                        <div>
                            {item ? <label className={'label label-success'}>Breaking News</label> : null}
                        </div>
                    )
                }
            },
            {
                title: 'Action',
                key: '_id',
                dataIndex: '_id',
                width: 120,
                render: (val, record) => (
                    <React.Fragment>
                        <Tooltip title="Edit Article ">
                            <Button style={{marginRight: 6, marginBottom: 6}} shape="circle" size={'small'}
                                    onClick={() => {
                                        dispatch(getPushPathWrapper('article.editArticle', {id: val}))
                                    }} icon="edit"/>
                        </Tooltip>

                        <Tooltip title="Delete Article ">
                            <Popconfirm
                                title="Are you sure delete this article?"
                                onConfirm={() => {
                                    this.articleDelete(record._id)
                                }}
                                onCancel={() => {
                                    console.log()
                                }}
                                okText="Yes"
                                cancelText="No">
                                <Button loading={this.state && this.state.deleteLoading}
                                        className={styles.btn}
                                        size={'small'}
                                        style={{marginBottom: 6}}
                                        shape="circle"
                                        icon="delete"
                                />
                            </Popconfirm>
                        </Tooltip>

                        <Tooltip title="Highlight News">
                            <Popconfirm
                                title={record.isHighlight ? 'Are you sure, You want to remove this form Highlight News?' : 'Are you sure, You want to make this Highlight News?'}
                                onConfirm={() => {
                                    this.makeIsHighlightOrNot(record._id)
                                }}
                                onCancel={() => {
                                    console.log()
                                }}
                                okText="Yes"
                                style={{marginBottom: 6}}
                                cancelText="No">
                                <Button className={styles.btn}
                                        size={'small'}
                                        shape="circle"
                                        icon="reload"
                                />
                            </Popconfirm>
                        </Tooltip>
                        {/*<Tooltip title="View Like ">*/}
                        {/*<Button*/}
                        {/*shape="circle"*/}
                        {/*style={{marginRight: 6, marginBottom: 6}}*/}
                        {/*onClick={() => {*/}
                        {/*this.setState({*/}
                        {/*likeData: record*/}
                        {/*}, () => {*/}
                        {/*this.showDrawer()*/}
                        {/*this.likeDetails({_id: record._id})*/}

                        {/*})*/}
                        {/*}}*/}
                        {/*icon="heart"*/}
                        {/*size={'small'}*/}

                        {/*/>*/}

                        {/*</Tooltip>*/}
                        {/*<Tooltip title="View Share ">*/}
                        {/*<Button*/}
                        {/*shape="circle"*/}
                        {/*style={{marginRight: 6}}*/}
                        {/*onClick={() => {*/}
                        {/*this.setState({*/}
                        {/*shareData: record*/}
                        {/*}, () => {*/}
                        {/*this.showDrawer1()*/}
                        {/*this.shareDetails({_id: record._id})*/}

                        {/*})*/}
                        {/*}}*/}
                        {/*icon="share-alt"*/}
                        {/*size={'small'}*/}

                        {/*/>*/}

                        {/*</Tooltip>*/}
                        {/*<Tooltip title="View View ">*/}
                        {/*<Button*/}
                        {/*shape="circle"*/}
                        {/*style={{marginRight: 6}}*/}
                        {/*onClick={() => {*/}
                        {/*this.setState({*/}
                        {/*viewData: record*/}
                        {/*}, () => {*/}
                        {/*this.showDrawer2()*/}
                        {/*this.viewDetails({_id: record._id})*/}

                        {/*})*/}
                        {/*}}*/}
                        {/*icon="eye"*/}
                        {/*size={'small'}*/}

                        {/*/>*/}

                        {/*</Tooltip>*/}
                        {/*<Tooltip title="View Comments ">*/}
                        {/*<Button*/}
                        {/*shape="circle"*/}
                        {/*style={{marginRight: 6}}*/}
                        {/*onClick={() => {*/}
                        {/*this.setState({*/}
                        {/*commentData: record*/}
                        {/*}, () => {*/}
                        {/*this.showDrawer3()*/}
                        {/*this.commentDetails({_id: record._id})*/}

                        {/*})*/}
                        {/*}}*/}
                        {/*icon="message"*/}
                        {/*size={'small'}*/}

                        {/*/>*/}

                        {/*</Tooltip>*/}


                    </React.Fragment>
                )
            }


        ]
        const columns1 = [
            {
                title: 'Name',
                dataIndex: 'userId.name',
                key: 'name'

            },
            {
                title: 'Mode',
                dataIndex: 'userId.mode',
                key: 'mode'
            },
            {
                title: 'Email',
                dataIndex: 'userId.email',
                key: 'email'

            },
            {
                title: 'Mobile No',
                dataIndex: 'userId.mobile',
                key: 'mobile'

            },
            {
                title: 'Time',
                key: 'deviceDetails',
                dataIndex: 'deviceDetails',
                width: 100,
                render: (text, record) => {
                    return (
                        <div>
                            <div>{text ? moment(text.time).format('lll') : ''}</div>
                        </div>
                    )
                }
            },


        ]
        const columns2 = [
            {
                title: 'Name',
                dataIndex: 'userId.name',
                key: 'name'
            },
            {
                title: 'Mode',
                dataIndex: 'userId.mode',
                key: 'mode'
            },
            {
                title: 'Email',
                dataIndex: 'userId.email',
                key: 'email'

            },

            {
                title: 'Mobile No',
                dataIndex: 'userId.mobile',
                key: 'mobile'

            },
            {
                title: 'Comment',
                dataIndex: 'comment',
                key: 'comment',
                searchTextName: 'comment'

            },
            {
                title: 'Time',
                key: 'createdBy.name',
                dataIndex: 'createdBy',
                width: 100,
                render: (text, record) => {
                    return (
                        <div>
                            <div>{text ? moment(text.time).format('lll') : ''}</div>
                        </div>
                    )
                }
            },
            {
                title: 'Action',
                key: '_id',
                dataIndex: '_id',
                width: 120,
                render: (val, record) => (
                    <React.Fragment>


                        <Tooltip title="Delete Comment ">
                            <Popconfirm
                                title="Are you sure delete this comment?"
                                onConfirm={() => {
                                    this.deleteComment(record._id)
                                }}
                                onCancel={() => {
                                    console.log()
                                }}
                                okText="Yes"
                                cancelText="No">
                                <Button loading={this.state && this.state.deleteLoading}
                                        className={styles.btn}
                                        size={'small'}
                                        shape="circle"
                                        icon="delete"
                                />
                            </Popconfirm>
                        </Tooltip>

                    </React.Fragment>
                )
            }


        ]

        return (
            <PageHeaderWrapper
                title={'All Articles'}>
                <Card bordered={true} width={500}>
                    <Row>
                        <Col span={4}>
                            <h4>Breaking News</h4>
                            <Select onChange={(e) => this.chooseBreaking(e)} style={{width: '100%'}}
                                    value={this.state.isHighlight}>
                                <Option value='All'>All</Option>
                                <Option value='Yes'>Yes</Option>
                                <Option value='No'>No</Option>
                            </Select>
                        </Col>
                    </Row>
                    <TableComp columns={columns}
                               ref={this.table}
                               apiRequest={this.apiRequest}/>
                </Card>
                <Drawer
                    title=""
                    placement="right"
                    closable={true}
                    onClose={() => {
                        this.setState({
                            visible: false,
                            likeDemo: null
                        })
                    }}
                    visible={this.state.visible}
                    width={800}>
                    <div style={{marginLeft: 20, marginRight: 20}}>

                        <Row>
                            <Col span={6}>
                                <h2 style={{marginTop: 20}}>View Likes List</h2>
                            </Col>
                        </Row>
                        <Row>

                            <Col span={24}>
                                <TableComp ref={this.table}
                                           columns={columns1}
                                           dataSource={this.state && this.state.likeDemo}
                                           loading={this.state && !this.state.likeDemo ? true : false}
                                />
                            </Col>

                        </Row>

                    </div>


                </Drawer>
                <Drawer
                    title=""
                    placement="right"
                    closable={true}
                    onClose={() => {
                        this.setState({
                            visible1: false,
                            shareDemo: null
                        })
                    }}
                    visible={this.state.visible1}
                    width={800}>
                    <div style={{marginLeft: 20, marginRight: 20}}>
                        <Row>
                            <Col span={6}>
                                <h2 style={{marginTop: 20}}>View Share List</h2>
                            </Col>
                        </Row>
                        <Row>

                            <Col span={24}>
                                <TableComp ref={this.table}
                                           columns={columns1}
                                           dataSource={this.state && this.state.shareDemo}
                                           loading={this.state && !this.state.shareDemo ? true : false}
                                />
                            </Col>

                        </Row>

                    </div>


                </Drawer>

                <Drawer
                    title=""
                    placement="right"
                    closable={true}
                    onClose={() => {
                        this.setState({
                            visible2: false,
                            viewDemo: null
                        })
                    }}
                    visible={this.state.visible2}
                    width={800}>
                    <div style={{marginLeft: 20, marginRight: 20}}>
                        <Row>
                            <Col span={6}>
                                <h2 style={{marginTop: 20}}>View Views List</h2>
                            </Col>
                        </Row>
                        <Row>

                            <Col span={24}>
                                <TableComp ref={this.table}
                                           columns={columns1}
                                           dataSource={this.state && this.state.viewDemo}
                                           loading={this.state && !this.state.viewDemo ? true : false}
                                />
                            </Col>

                        </Row>

                    </div>


                </Drawer>

                <Drawer
                    title=""
                    placement="right"
                    closable={true}
                    onClose={() => {
                        this.setState({
                            visible3: false,
                            commentDemo: null
                        })
                    }}
                    visible={this.state.visible3}
                    width={800}>
                    <div style={{marginLeft: 20, marginRight: 20}}>
                        <Row>
                            <Col span={6}>
                                <h2 style={{marginTop: 20}}>View Comment List</h2>
                            </Col>
                        </Row>
                        <Row>

                            <Col span={24}>
                                <TableComp ref={this.table1}
                                           columns={columns2}
                                           onChange={(pagination, filters, sorter) => {
                                               let _id = this.state && this.state.articleId
                                               this.commentDetails({...filters, _id})
                                           }}
                                           dataSource={this.state && this.state.commentDemo}
                                           loading={this.state && !this.state.commentDemo ? true : false}
                                />
                            </Col>

                        </Row>

                    </div>


                </Drawer>


            </PageHeaderWrapper>)

    }
}


const mapStateToProps = ({global}) => ({
    categories: global.categories

})
const mapDispatchToProps = dispatch => {
    return {
        dispatch
    }
}


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AllArticles)
