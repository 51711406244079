import React, { PureComponent } from 'react'
import PageHeaderWrapper from '../../../components/PageHeaderWrapper'
import {
  Form,
  Input,
  DatePicker,
  Select,
  Button,
  Card,
  Icon
} from 'antd'
import { ChromePicker } from 'react-color'
import _ from 'lodash'
import moment from 'moment'
import { FormUtils as GetAllFormFields } from 'sz-react-utils'

import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import { notification } from 'antd/lib/index'
import { hideLoader, showLoader } from '../../../modules/actions'
import Request from '../../../request'
import { API_URL } from '../../../request'

import { connect } from 'react-redux'
import { createMatchSelector } from 'connected-react-router'
import { getUrlParams } from '../../../routes'


const FormItem = Form.Item
const { Option } = Select

@Form.create()
class AddArticle extends PureComponent {

  handleSubmit = e => {
    const { dispatch, form } = this.props
    e.preventDefault()
    form.validateFieldsAndScroll(async (err, valData) => {
      if (!err) {
        let values = _.clone(valData)

        dispatch(showLoader())

        let x = null
        x = await Request.addYoutubeVideo(values)

        dispatch(hideLoader())

        if (!x.error) {

          notification.success({
            message: x.message
          })


          this.props.form.resetFields()

        } else {
          notification.error({
            message: 'Error Saving',
            description: x.message
          })
        }

      }
    })
  }

  constructor(props) {
    super(props)
    this.state = {
      major: '',
      mode: '',
      category: '',
      id: '',
      catArr: []
    }

  }

  async componentDidMount() {

  }


  render() {

    const { submitting, dispatch } = this.props
    const {
      form: { getFieldDecorator, getFieldValue }
    } = this.props

    const { editorState } = this.state

    const inputTypes = {
      fields: [
        {
          label: 'Video Id',
          key: 'videoId',
          required: true

        },
        {
          title: 'Title',
          type: 'textArea',
          key: 'title',
          required: true
        },
        {
          title: 'Description',
          type: 'textArea',
          key: 'description',
          required: true
        }
      ]
    }

    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 8 },
        md: { span: 8 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
        md: { span: 12 }
      }
    }

    const submitFormLayout = {
      wrapperCol: {
        xs: { span: 24, offset: 0 },
        sm: { span: 10, offset: 7 },
        md: { span: 12, offset: 8 }
      }
    }

    const formItemLayoutWithOutLabel = {
      wrapperCol: {
        xs: { span: 24, offset: 0 },
        sm: { span: 20, offset: 4 }
      }
    }
    const handleChange = () => {

    }

    return (
      <PageHeaderWrapper
        title={this.state.id ? 'Update Youtube Video' : 'Add Youtube Video'}>

        <Card bordered={true}>
          <Form onSubmit={this.handleSubmit} hideRequiredMark style={{ marginTop: 8 }}>

            <GetAllFormFields apiurl={API_URL} inputSchema={inputTypes} formItemLayout={formItemLayout}
                              getFieldDecorator={getFieldDecorator}/>

            <Form.Item {...submitFormLayout} style={{ marginTop: 32 }}>
              <Button type="primary" htmlType="submit" loading={this.props.loading}>
                SAVE
              </Button>
            </Form.Item>

          </Form>
        </Card>

      </PageHeaderWrapper>
    )
  }
}

const mapStateToProps = ({ global, router }) => ({
  loading: global.buttonLoading,
  categories: global.categories,
  pathname: router.location.pathname

})
const mapDispatchToProps = dispatch => {
  return {
    dispatch
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddArticle)
