import axios from 'axios'

import { apiUrl } from './settings'

export const API_URL = apiUrl

let authAxios = axios.create({
  baseURL: apiUrl
})

let getToken = () => {
  return ({ 'headers': { 'Authorization': 'Bearer ' + localStorage.getItem('token') } })
}

class Request {

  error = (err) => {
    try {
      if (err.response.status === 401) {
        localStorage.clear()
      }
    } catch (e) {
    }
  }

  constructor() {

  }

  login(data) {
    return new Promise((next, error) => {
      authAxios.post('/loginWithPassword', data)
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          next({ error: true, err })
          this.error(err)
        })

    })
  }


  addAdvertisement(data) {
    return new Promise(next => {
      authAxios
        .post('/advertisement', data, getToken())
        .then(d => {
          next(d.data)
        })
        .catch(err => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }

  getAllAdvertisements(data) {
    return new Promise((next) => {
      authAxios
        .post('/advertisements/list', { params: { ...data } }, getToken())
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          next({ error: true, err })
          this.error(err)
        })

    })
  }


  addArticle(data) {
    return new Promise(next => {
      authAxios
        .post('/article', data, getToken())
        .then(d => {
          next(d.data)
        })
        .catch(err => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }

  getAllArticles(data) {
    return new Promise((next) => {
      authAxios
        .post('/articles/list', { params: { ...data }, ...getToken() })
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          next({ error: true, err })
          this.error(err)
        })

    })
  }

  deleteArticle(data) {
    return new Promise(next => {
      authAxios
        .delete(`/article/` + data._id, getToken())
        .then(d => {
          next(d.data)
        })
        .catch(err => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }

  getArticleById(data) {
    return new Promise(next => {
      authAxios
        .get('/singleArticle/' + data._id, getToken())
        .then(d => {
          next(d.data)
        })
        .catch(err => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }

  updateArticle(data) {
    return new Promise(next => {
      authAxios
        .put('/article/' + data._id, data, getToken())
        .then(d => {
          next(d.data)
        })
        .catch(err => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }

  getAdvertisementById(data) {
    return new Promise(next => {
      authAxios
        .get('/advertisement/' + data._id, getToken())
        .then(d => {
          next(d.data)
        })
        .catch(err => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }

  updateAdvertisement(data) {
    return new Promise(next => {
      authAxios
        .put('/advertisement/' + data._id, data, getToken())
        .then(d => {
          next(d.data)
        })
        .catch(err => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }

  deleteAdvertisement(data) {
    return new Promise(next => {
      authAxios
        .delete(`/advertisement/` + data._id, getToken())
        .then(d => {
          next(d.data)
        })
        .catch(err => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }

  getCategoryList() {
    return new Promise((next) => {
      authAxios
        .post('/api/category')
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          next({ error: true, err })
          this.error(err)
        })

    })
  }


  makeIsHighlightOrNot(data) {
    return new Promise(next => {
      authAxios
        .post('/api/makeIsHighlightOrNot', data, getToken())
        .then(d => {
          next(d.data)
        })
        .catch(err => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }

  getLikeDetails(data) {
    return new Promise(next => {
      authAxios
        .post('/likes/list/article', data, getToken())
        .then(d => {
          next(d)
        })
        .catch(err => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }

  getShareDetails(data) {
    return new Promise(next => {
      authAxios
        .post('/share/list/article', data, getToken())
        .then(d => {
          next(d)
        })
        .catch(err => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }


  getViewDetails(data) {
    return new Promise(next => {
      authAxios
        .post('/view/list/article', data, getToken())
        .then(d => {
          next(d)
        })
        .catch(err => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }

  getCommentDetails(data) {
    return new Promise(next => {
      authAxios
        .post('/comment/list/article', data, getToken())
        .then(d => {
          next(d)
        })
        .catch(err => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }

  deleteComment(data) {
    return new Promise(next => {
      authAxios
        .delete(`/comments/delete/ByAdmin/` + data._id, getToken())
        .then(d => {
          next(d.data)
        })
        .catch(err => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }


  getYoutubeVideoList(data) {
    return new Promise(next => {
      authAxios
        .get('/api/youtubeVideos', { params: { ...data } })
        .then(d => {
          next(d.data)
        })
        .catch(err => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }


  deleteYoutubeVideo(data) {
    return new Promise(next => {
      authAxios
        .post(`/api/deleteYoutubeVideo`, data, getToken())
        .then(d => {
          next(d.data)
        })
        .catch(err => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }

  addYoutubeVideo(data) {
    return new Promise(next => {
      authAxios
        .post('/api/addYoutubeVideo', data, getToken())
        .then(d => {
          next(d.data)
        })
        .catch(err => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }


  updateYoutubeVideo(data) {
    return new Promise(next => {
      authAxios
        .post('/api/updateYoutubeVideo', data, getToken())
        .then(d => {
          next(d.data)
        })
        .catch(err => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }

  uploadFile(data) {
    return new Promise((next) => {
      authAxios
        .post('/upload/uploadFile', data, getToken())
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          next({ error: true, err })
          this.error(err)
        })

    })
  }


  uploadsList() {
    return new Promise(next => {
      authAxios
        .get('/upload/list')
        .then(d => {
          next(d.data)
        })
        .catch(err => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }

  deleteUploads(data) {
    return new Promise((next) => {
      authAxios
        .post('/upload/delete', data, getToken())
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          next({ error: true, err })
          this.error(err)
        })

    })
  }
}

export default new Request()
