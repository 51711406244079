import { push } from 'connected-react-router'
import _ from 'lodash'
import Path from 'path-parser'

import Dashboard from './containers/dashboard'
import Undercons from './containers/undercons'

import AddAdvertisement from './containers/advertisement/add'
import AllAdvertisements from './containers/advertisement/all'
import AddArticle from './containers/article/add'
import AddYoutubeVideo from './containers/youtube/add'
import AllArticles from './containers/article/all'
import AllYoutubeVideo from './containers/youtube/all'
import UploadAndList from './containers/uploadImage/add'


const menu = [
  {
    'path': '/dashboard',
    'name': 'Dashboard',
    'icon': 'dashboard',
    'key': 'dashboard',
    'homepage': true,
    'component': Dashboard,
    'authority': [
      'admin',
      'user'
    ]
  },
  {
    'path': '/advertisement',
    'name': 'Advertisement',
    'icon': 'user',
    'key': 'advertisement',
    'authority': [
      'admin',
      'user'
    ],
    'children': [
      {
        'path': '/advertisement/add',
        'name': 'Add Advertisement',
        'title': 'Add Advertisement',
        'component': AddAdvertisement
      },
      {
        'path': '/advertisements/all',
        'name': 'All Advertisements',
        'title': 'All Advertisements',
        'component': AllAdvertisements
      },
      {
        'path': '/advertisement/edit-advertisement',
        'name': 'Update Advertisement',
        'title': 'Update Advertisement',
        'key': 'editAdvertisement',
        dontShowOnMenu: true,
        'component': AddAdvertisement
      }
    ]
  },
  {
    'path': '/article',
    'name': 'Article',
    'icon': 'user',
    'key': 'article',
    'authority': [
      'admin',
      'user'
    ],
    'children': [
      {
        'path': '/article/add',
        'name': 'Add article',
        'title': 'Add article',
        'component': AddArticle
      },
      {
        'path': '/articles/all',
        'name': 'All Articles',
        'title': 'All Articles',
        'component': AllArticles
      },
      {
        key: 'editArticle',
        dontShowOnMenu: true,
        'path': '/article/edit/:id',
        'name': 'Edit Article',
        'title': 'Edit Article',
        'component': AddArticle
      }
    ]
  },
  {
    'path': '/youtube',
    'name': 'Youtube',
    'icon': 'user',
    'key': 'youtube',
    'authority': [
      'admin',
      'user'
    ],
    'children': [
      {
        'path': '/youtube/add',
        'name': 'Add Youtube Video',
        'title': 'Add Youtube Video',
        'component': AddYoutubeVideo
      },
      {
        'path': '/youtube/all',
        'name': 'All Youtube Video',
        'title': 'All Youtube Video',
        'component': AllYoutubeVideo
      }
    ]
  },
  {
    'path': '/uploadImage',
    'name': 'Upload Image',
    'icon': 'user',
    'key': 'uploadImage',
    'authority': [
      'admin',
      'user'
    ],
    'children': [
      {
        'path': '/uploadImage/add',
        'name': 'Upload Image',
        'title': 'Upload Image',
        'component': UploadAndList
      }
    ]
  }

]

//<editor-fold desc="Functions Exports">
export const getUrlPushWrapper = (keyString, query) => {
  return push(getUrlPath(keyString, query))
}

export const getUrlPath = (keyString, params) => {

  if (!params) params = {}

  let keyArr = keyString.split('.')

  let val = _.find(menu, p => p.key === keyArr[0])

  if (!val) {
    return `/`
  }

  if (keyArr.length === 2) {
    val = _.find(val.children, p => p.key === keyArr[1])
  }

  if (!val) {
    return `/`
  }

  let queryString = Object.keys(params).map(key => key + '=' + params[key]).join('&')

  return `${val.path}?${queryString}`
}

export const getPushPathWrapper = (keyString, params) => {

  let obj = getUrlObject(keyString)

  if (obj) {
    const path = new Path(obj.path)

    return push(path.build(params))
  }

  return 'error'
}

export const getUrlParams = (keyString, route) => {

  let obj = getUrlObject(keyString)

  if (obj) {
    const path = new Path(obj.path)

    return path.test(route)
  }

  return { error: true }
}

export const getUrlObject = (keyString) => {

  let keyArr = keyString.split('.')

  let val = _.find(menu, p => p.key === keyArr[0])

  if (!val) {
    return `/`
  }

  if (keyArr.length === 2) {
    val = _.find(val.children, p => p.key === keyArr[1])
  }

  if (!val) {
    return `/`
  }

  return val
}
//</editor-fold>

export default menu

