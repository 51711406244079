import React, {PureComponent} from 'react'
import PageHeaderWrapper from '../../../components/PageHeaderWrapper'
import {
    Form,
    Input,
    DatePicker,
    Select,
    Button,
    Card,
    Icon
} from 'antd'
import {ChromePicker} from 'react-color'
import _ from 'lodash'
import moment from 'moment'
import {FormUtils as GetAllFormFields} from 'sz-react-utils'

import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import {notification} from 'antd/lib/index'
import {hideLoader, showLoader} from '../../../modules/actions'
import Request from '../../../request'
import {API_URL} from '../../../request'

import {connect} from 'react-redux'
import {createMatchSelector} from 'connected-react-router'
import {getUrlParams} from '../../../routes'


const FormItem = Form.Item
const {Option} = Select

@Form.create()
class AddArticle extends PureComponent {

    constructor(props) {
        super(props)
        this.state = {
            major: '',
            mode: '',
            category: '',
            id: '',
            catArr: []
        }

    }

    handleSubmit = e => {
        const {dispatch, form} = this.props
        e.preventDefault()
        form.validateFieldsAndScroll(async (err, valData) => {
            if (!err) {
                let values = _.clone(valData)

                if (values.featuredImage && values.featuredImage.length) {
                    values.featuredImage = _.map(values.featuredImage, (item) => {
                        return item.response ? item.response : item
                    })
                }
                values.featuredImage = values.featuredImage[0].url

                let catId1 = _.find(this.state.catArr, (vv) => {
                    return vv.id == values.category
                })
                let catId = _.find(this.state.catArr, (vv) => {
                    return vv.name == values.category
                })

                if (catId) {
                    values.category = catId.wpid
                }
                if (catId1) {
                    values.category = catId1.wpid
                }

                dispatch(showLoader())

                let x = null
                if (this.state && this.state.id) {
                    values._id = this.state.id
                    values.categories = [values.category]
                    x = await Request.updateArticle(values)
                } else {
                    values.categories = [values.category]
                    x = await Request.addArticle(values)
                    this.props.form.resetFields()
                }

                dispatch(hideLoader())

                if (!x.error) {

                    notification.success({
                        message: this.state.id
                            ? x.message
                            : x.message
                    })

                    this.props.form.setFieldsValue({})

                } else {
                    notification.error({
                        message: 'Error Saving',
                        description: x.message
                    })
                }

            }
        })
    }

    async componentDidMount() {
        let data = getUrlParams('article.editArticle', this.props.pathname)

        if (data && data.id) {
            Request.getArticleById({_id: data.id}).then(({data, error, message}) => {
                if (!error) {
                    console.log()
                    this.setState({
                        id: data._id
                    })

                    this.props.form.setFieldsValue({
                        title: data && data.title,
                        body: data && data.body,
                        slug: data && data.slug,
                        category: data && data.category,
                        tags: data && data.tags,
                        videos: data && data.videos,
                        publishDate: data && moment(data.publishDate),
                        featuredImage: data && data.featuredImage && [{
                            url: data.featuredImage,
                            uid: '34543534534',
                            name: data.featuredImage
                        }],
                        featuredVideo: data && data.featuredVideo
                    })


                } else {

                    notification.error({
                        message: 'Error Getting Data'
                    })

                }
            })
        }


        let categoryArr = await Request.getCategoryList()

        this.setState({catArr: categoryArr})


    }


    render() {

        const {submitting, dispatch} = this.props
        const {
            form: {getFieldDecorator, getFieldValue}
        } = this.props

        const {editorState} = this.state

        const inputTypes = {
            fields: [
                {
                    label: 'Title',
                    key: 'title',
                    required: true

                },
                {
                    label: 'Slug',
                    key: 'slug',
                    required: true

                },
                {
                    title: 'Body',
                    type: 'ckeditor',
                    customDirectives: {
                        initialValue: ''
                    },
                    key: 'body',
                    required: true
                },
                {
                    label: 'Tags',
                    key: 'tags',
                    // type: 'select', mode: 'tags',
                    onChange: v => {
                        this.props.form.setFieldsValue({mode: v})
                    }
                },
                {
                    label: 'Choose Category', type: 'select',
                    onChange: v => {
                        let findInd = _.find(this.state.catArr, (vv) => {
                            return vv.id == v
                        })
                        this.props.form.setFieldsValue({
                            category: findInd && findInd.name
                        })
                    },
                    key: 'category',
                    keyAccessor: x => x.id,
                    valueAccessor: x => x.name,
                    options: this.state ? this.state.catArr : [],
                    required: true
                },
                {
                    label: 'Publish Date',
                    key: 'publishDate',
                    type: 'date'

                },


                {
                    label: 'Featured Image',
                    key: 'featuredImage',
                    type: 'file',
                    required: true

                },
                {
                    label: 'Featured Video',
                    key: 'featuredVideo',

                }
            ]
        }

        const formItemLayout = {
            labelCol: {
                xs: {span: 24},
                sm: {span: 8},
                md: {span: 8}
            },
            wrapperCol: {
                xs: {span: 24},
                sm: {span: 16},
                md: {span: 12}
            }
        }

        const submitFormLayout = {
            wrapperCol: {
                xs: {span: 24, offset: 0},
                sm: {span: 10, offset: 7},
                md: {span: 12, offset: 8}
            }
        }

        const formItemLayoutWithOutLabel = {
            wrapperCol: {
                xs: {span: 24, offset: 0},
                sm: {span: 20, offset: 4}
            }
        }
        const handleChange = () => {

        }

        return (
            <PageHeaderWrapper
                title={this.state.id ? 'Update Article' : 'Add Article'}
            >

                <Card bordered={true}>
                    <Form onSubmit={this.handleSubmit} hideRequiredMark style={{marginTop: 8}}>

                        <GetAllFormFields apiurl={API_URL} inputSchema={inputTypes} formItemLayout={formItemLayout}
                                          getFieldDecorator={getFieldDecorator}/>

                        <Form.Item {...submitFormLayout} style={{marginTop: 32}}>
                            <Button type="primary" htmlType="submit" loading={this.props.loading}>
                                SAVE
                            </Button>
                        </Form.Item>

                    </Form>
                </Card>

            </PageHeaderWrapper>
        )
    }
}

const mapStateToProps = ({global, router}) => ({
    loading: global.buttonLoading,
    categories: global.categories,
    pathname: router.location.pathname

})
const mapDispatchToProps = dispatch => {
    return {
        dispatch
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AddArticle)
