import React, { Component } from 'react'
import PageHeaderWrapper from '../../../components/PageHeaderWrapper'
//import {TableComp} from 'sz-react-utils'

import TableComp from '../../../components/_utils/table'
import moment from 'moment'
import {
  Form,
  Table,
  Badge,
  Drawer,
  Popconfirm,
  Card,
  Tooltip,
  notification,
  Switch,
  Input, Button, Icon,
  Row,
  Col,
  Select
} from 'antd'
import Request, { API_URL } from '../../../request'
import Color from 'color'
import _ from 'lodash'
import Highlighter from 'react-highlight-words'
import styles from './styles.less'
import S from 'string'
import { connect } from 'react-redux'
import memoizeOne from 'memoize-one'
import update from 'immutability-helper'
import { getPushPathWrapper } from '../../../routes'
import { FormUtils as GetAllFormFields } from 'sz-react-utils'
import { hideLoader, showLoader } from '../../../modules/actions'

const { Option } = Select

@Form.create()
class AllYoutubeVideo extends Component {

  apiRequest = (params) => {
    return new Promise(async (resolve) => {
      if (!params.sortField) {
        params.sortField = 'publishedAt'
        params.sortOrder = 'descend'
      }
      let data = await Request.getYoutubeVideoList({ ...params })
      resolve(data)
    })
  }

  deleteYoutubeVideo = async (_id) => {
    this.setState({ deleteLoading: true })
    let x = await Request.deleteYoutubeVideo({ _id: _id })
    this.setState({ deleteLoading: false })
    if (x && !x.error) {
      notification.success({
        message: 'Deleted Successfully'
      })
      this.table.current.reload()
    } else {
      notification.error({ message: 'Try again' })
    }
  }
  showDrawer = (val) => {
    this.setState({
      visible: true,
      videoId: val._id
    })

    setTimeout(() => {
      this.props.form.setFieldsValue({
        title: val && val.title,
        description: val && val.snippet && val.snippet.description
      })
    }, 1000)
  }

  handleSubmit = e => {
    const { dispatch, form } = this.props
    e.preventDefault()
    form.validateFieldsAndScroll(async (err, valData) => {
      if (!err) {
        let values = _.clone(valData)


        dispatch(showLoader())

        values._id = this.state.videoId
        let x = await Request.updateYoutubeVideo(values)

        dispatch(hideLoader())

        if (!x.error) {

          notification.success({
            message: x.message
          })
          this.setState({
            visible: false,
            videoId: null
          })

          this.table.current.reload()

        } else {
          notification.error({
            message: 'Error Saving',
            description: x.message
          })
        }

      }
    })
  }


  constructor(props) {
    super(props)
    this.state = {
      visible: false
    }
    this.table = React.createRef()

  }

  async componentDidMount() {

  }


  render() {
    const { dispatch } = this.props
    const {
      form: { getFieldDecorator, getFieldValue }
    } = this.props

    const columns = [
      {
        title: 'Video',
        dataIndex: 'id',
        key: 'id',
        render: (item, record) => {
          return (
            <iframe width="150" height="100"
                    src={`https://www.youtube.com/embed/${item.videoId}`}>
            </iframe>
          )
        }
      },
      {
        title: 'Title',
        dataIndex: 'title',
        key: 'title',
        searchTextName: 'title'
      },
      {
        title: 'Publish Date',
        dataIndex: 'publishedAt',
        sorter: true,
        key: 'publishedAt',
        render: (val, record) => {
          return <div>
            {moment(val).format('YYYY-MMM-DD')}

          </div>
        }
      },
      {
        title: 'Action',
        key: '_id',
        dataIndex: '_id',
        width: 120,
        render: (val, record) => (
          <React.Fragment>
            <Tooltip title="Delete Video ">
              <Popconfirm
                title="Are you sure delete this video?"
                onConfirm={() => {
                  this.deleteYoutubeVideo(record._id)
                }}
                onCancel={() => {
                  console.log()
                }}
                okText="Yes"
                cancelText="No">
                <Button loading={this.state && this.state.deleteLoading}
                        className={styles.btn}
                        size={'small'}
                        style={{ marginBottom: 6 }}
                        shape="circle"
                        icon="delete"
                />
              </Popconfirm>
            </Tooltip>

            <Tooltip title="Update Video">
              <Button className={styles.btn}
                      size={'small'}
                      style={{ marginBottom: 6 }}
                      shape="circle"
                      icon="eye"
                      onClick={() => this.showDrawer(record)}
              />
            </Tooltip>
          </React.Fragment>
        )
      }


    ]

    const inputTypes = {
      fields: [
        {
          title: 'Title',
          type: 'textArea',
          key: 'title',
          required: true
        },
        {
          title: 'Description',
          type: 'textArea',
          key: 'description',
          required: true
        }
      ]
    }

    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 6 },
        md: { span: 6 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 18 },
        md: { span: 18 }
      }
    }

    const submitFormLayout = {
      wrapperCol: {
        xs: { span: 24, offset: 0 },
        sm: { span: 18, offset: 6 },
        md: { span: 18, offset: 6 }
      }
    }


    return (
      <PageHeaderWrapper
        title={'All Youtube Video'}>
        <Card bordered={true} width={500}>
          <TableComp columns={columns}
                     ref={this.table}
                     apiRequest={this.apiRequest}/>
        </Card>
        <Drawer
          title=""
          placement="right"
          closable={true}
          onClose={() => {
            this.setState({
              visible: false,
              likeDemo: null
            })
          }}
          visible={this.state.visible}
          width={1000}>
          <div style={{ marginLeft: 20, marginRight: 20 }}>

            <Row>
              <Col span={6}>
                <h2 style={{ marginTop: 20 }}>Update Video</h2>
              </Col>
            </Row>
            <Row>

              <Col span={24}>
                <Form onSubmit={this.handleSubmit} style={{ marginTop: 8 }}>

                  <GetAllFormFields inputSchema={inputTypes} formItemLayout={formItemLayout}
                                    getFieldDecorator={getFieldDecorator}/>

                  <Form.Item {...submitFormLayout} style={{ marginTop: 32 }}>
                    <Button type="primary" htmlType="submit" loading={this.props.loading}>
                      UPDATE
                    </Button>
                  </Form.Item>

                </Form>
              </Col>

            </Row>

          </div>


        </Drawer>

      </PageHeaderWrapper>)

  }
}


const mapStateToProps = ({ global }) => ({
  categories: global.categories

})
const mapDispatchToProps = dispatch => {
  return {
    dispatch
  }
}


export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AllYoutubeVideo)
