import React, { Component } from 'react'
import PageHeaderWrapper from '../../../components/PageHeaderWrapper'
import { TableComp } from 'sz-react-utils'
import moment from 'moment'
import {
  Table,
  Badge,
  Card,
  Tooltip,
  Popconfirm,
  notification,
  Switch,
  Input, Button, Icon
} from 'antd'
import Request from '../../../request'
import Color from 'color'
import _ from 'lodash'
import Highlighter from 'react-highlight-words'
import styles from './styles.less'
import { connect } from 'react-redux'
import memoizeOne from 'memoize-one'
import update from 'immutability-helper'
import { getUrlPushWrapper } from '../../../routes'

class AllAdvertisements extends Component {

  deleteAdvertisement = async data => {
    this.setState({ loading: true })
    let x = await Request.deleteAdvertisement(data)
    this.setState({ loading: false })

    this.table.current.reload()
    if (!x.error) {
      notification.success({
        message: x.message,
        duration: 20,
        key: `${data._id}-close`
      })

    }
    else {
      notification.error({
        message: x.message,
        duration: 20,
        key: `${data._id}-close`
      })
    }


  }
  apiRequest = (params) => {
    return new Promise(async (resolve) => {
      let data = await Request.getAllAdvertisements({
        ...params, regExFilters: [
          'position', 'pageType'
        ]
      })
      resolve(data)
    })
  }

  constructor(props) {
    super(props)
    this.table = React.createRef()
    this.state = {
      pageTypeArr: ['Home', 'Article List', 'Single Article'],
      positionArr: ['Center', 'Top', 'Bottom']

    }

  }

  componentDidMount() {
  }

  render() {
    const { submitting, dispatch } = this.props
    const { pageTypeArr, positionArr } = this.state


    const columns = [
      {
        title: 'Photo',
        dataIndex: 'photos',
        key: 'photo',
        render: (val, record) => {
          return <div>
            <img src={val[0].url} style={{ height: 50, width: 50 }}/>
          </div>
        }
      },

      {
        title: 'From',
        dataIndex: 'dateRange.from',
        key: 'dateRange.from',
        render: (val, record) => {
          return <div>
            {record.dateRange && moment(record.dateRange.from).format('YYYY-MMM-DD')}
          </div>
        }
      },
      {
        title: 'To',
        dataIndex: 'dateRange.to',
        key: 'dateRange.to',
        render: (val, record) => {
          return <div>
            {record.dateRange && moment(record.dateRange.to).format('YYYY-MMM-DD')}
          </div>
        }
      },

      {
        title: 'Position',
        dataIndex: 'position',
        key: 'position',
        filters: positionArr && positionArr.map(x => ({ value: x, text: x })),
        render: (text, record) => {
          return (
            <div>{record.position ? record.position : ''}</div>

          )
        }

      },
      {
        title: 'PageType',
        key: 'pageType',
        dataIndex: 'pageType',
        filters: pageTypeArr && pageTypeArr.map(x => ({ value: x, text: x })),
        render: (text, record) => {
          return (
            <div>{record.pageType ? record.pageType : ''}</div>

          )
        }

      },

      {
        title: 'Created By',
        dataIndex: 'createdBy.name',
        key: 'createdBy.name',
        render: (val, record) => {
          return <div>
            <div>
              {val}
            </div>
            <div>{moment(record.time).format('YYYY-MMM-DD')}</div>
          </div>
        }
      },

      {
        key: 'actions',
        title: 'Actions', fixed: 'right',
        width: 150,
        render: (text, record) => {

          return <React.Fragment>
            <Tooltip title="Edit Advertisement">
              <Button
                shape="circle"
                style={{marginRight:6}}
                size="small"
                onClick={() => {
                  dispatch(
                    getUrlPushWrapper('advertisement.editAdvertisement', {
                      id: record._id
                    })
                  )
                }}
                icon="edit"
              />
            </Tooltip>

            <Tooltip title="Delete Advertisement">
              <Popconfirm
                title="Are you sure delete this Advertisement?"
                onConfirm={() => {
                  this.deleteAdvertisement({ _id: record._id })
                }}
                onCancel={() => {
                  console.log()
                }}
                okText="Yes"
                cancelText="No">
                <Button
                  shape="circle"
                  size="small"

                  icon="delete"
                />
              </Popconfirm>

            </Tooltip>

          </React.Fragment>

        }
      }

    ]
    return (
      <PageHeaderWrapper
        title={'All Advertisements'}>

        <Card bordered={true}>
          <TableComp ref={this.table}
                     columns={columns}
                     apiRequest={this.apiRequest}
                     pagination={{
                       ...this.state.pagination,
                       defaultPageSize: 10,
                       pageSizeOptions: ['10', '25', '50', '100'],
                       showSizeChanger: true,
                       ...this.props.pagination
                     }}/>
        </Card>

      </PageHeaderWrapper>)

  }
}


const mapStateToProps = ({ global }) => ({
  categories: global.categories
})
const mapDispatchToProps = dispatch => {
  return {
    dispatch
  }
}


export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AllAdvertisements)
