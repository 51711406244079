import React, {PureComponent} from 'react'
import PageHeaderWrapper from '../../../components/PageHeaderWrapper'
import {
    Form,
    Input,
    DatePicker,
    Select,
    Button,
    Card,
    Icon
} from 'antd'
import {ChromePicker} from 'react-color'
import _ from 'lodash'
import moment from 'moment'
import {FormUtils as GetAllFormFields} from 'sz-react-utils'
import {API_URL} from '../../../request'

import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import {notification} from 'antd/lib/index'
import {hideLoader, showLoader} from '../../../modules/actions'
import Request from '../../../request'
import {connect} from 'react-redux'
import {createMatchSelector} from 'connected-react-router'

const FormItem = Form.Item
const {Option} = Select

@Form.create()
class AddAdvertisement extends PureComponent {

    handleSubmit = e => {
        const {dispatch, form} = this.props
        e.preventDefault()
        form.validateFieldsAndScroll(async (err, valData) => {
            if (!err) {
                let values = _.clone(valData)
                let dateRange = {
                    to: values.to,
                    from: values.from
                }

                values.dateRange = dateRange
                if (values.photos && values.photos.length) {
                    values.photos = _.map(values.photos, (item) => {
                        return item.response ? item.response : item
                    })
                }
                _.each(values.photos, (item) => {
                    item.order = values.order
                })


                dispatch(showLoader())

                let x = null
                if (this.state.id) {
                    values._id = this.state.id
                    x = await Request.updateAdvertisement(values)
                } else {
                    x = await Request.addAdvertisement(values)
                    this.props.form.resetFields()
                }

                dispatch(hideLoader())

                if (!x.error) {

                    notification.success({
                        message: this.state.id
                            ? x.message
                            : x.message
                    })

                    this.props.form.setFieldsValue({})

                } else {
                    notification.error({
                        message: 'Error Saving',
                        description: x.message
                    })
                }

            }
        })
    }

    setFormValues = async (slug) => {

    }

    constructor(props) {
        super(props)
        this.state = {
            major: ''
        }

    }

    async componentDidMount() {
        let searchParams = new URLSearchParams(this.props.search)

        let advertisementId = searchParams.get('id')
        if (advertisementId) {
            Request.getAdvertisementById({_id: advertisementId}).then(({data, error, message}) => {
                if (!error) {
                    this.setState({
                        id: advertisementId
                    })
                    _.each(data.photos, (item) => {
                        this.props.form.setFieldsValue({
                            order: item.order,
                        })
                    })
                    this.props.form.setFieldsValue({
                        position: data && data.position,
                        pageType: data && data.pageType,
                        from: data && moment(data.dateRange.from),
                        to: data && moment(data.dateRange.to),
                        clientName: data && data.clientName,
                        redirectUrl: data && data.redirectUrl,
                        photos: data && data.photos,

                    })
                } else {
                    notification.error({
                        message: 'Error Getting Data'
                    })
                }
            })
        }

    }

    render() {

        const {submitting} = this.props
        const {
            form: {getFieldDecorator, getFieldValue}
        } = this.props

        const {editorState} = this.state

        const inputTypes = {
            fields: [
                {
                    label: 'Position',
                    key: 'position',
                    required: true,
                    type: 'select', options: ['Center', "Top", "Bottom"],
                    onChange: v => {
                        this.props.form.setFieldsValue({position: v})
                    }

                },
                {
                    label: 'Page Type',
                    key: 'pageType',
                    required: true,
                    type: 'select', options: ['Home', 'Article List', 'Single Article'],
                    onChange: v => {
                        this.props.form.setFieldsValue({pageType: v})
                    }

                },
                {
                    label: 'From',
                    key: 'from',
                    required: true,
                    type: 'date'

                },
                {
                    label: 'To',
                    key: 'to',
                    type: 'date',
                    required: true
                },
                {
                    label: 'Redirect Url',
                    key: 'redirectUrl',
                    required: true
                },
                {
                    label: 'Client Name',
                    key: 'clientName',
                    required: true
                },


                {label: 'Photo', key: 'photos', type: 'file', required: true},
                {label: 'Order', key: 'order', type: 'number', required: true}

            ]
        }

        const formItemLayout = {
            labelCol: {
                xs: {span: 24},
                sm: {span: 8},
                md: {span: 8}
            },
            wrapperCol: {
                xs: {span: 24},
                sm: {span: 16},
                md: {span: 12}
            }
        }

        const submitFormLayout = {
            wrapperCol: {
                xs: {span: 24, offset: 0},
                sm: {span: 10, offset: 7},
                md: {span: 12, offset: 8}
            }
        }

        const formItemLayoutWithOutLabel = {
            wrapperCol: {
                xs: {span: 24, offset: 0},
                sm: {span: 20, offset: 4}
            }
        }
        const handleChange = () => {

        }

        return (
            <PageHeaderWrapper
                title={this.state.id ? 'Update Advertisement' : 'Add Advertisement'}
            >

                <Card bordered={true}>
                    <Form onSubmit={this.handleSubmit} hideRequiredMark style={{marginTop: 8}}>

                        <GetAllFormFields apiurl={API_URL}
                                          inputSchema={inputTypes} formItemLayout={formItemLayout}
                                          getFieldDecorator={getFieldDecorator}/>

                        <Form.Item {...submitFormLayout} style={{marginTop: 32}}>
                            <Button type="primary" htmlType="submit" loading={this.props.loading}>
                                SAVE
                            </Button>
                        </Form.Item>

                    </Form>
                </Card>

            </PageHeaderWrapper>
        )
    }
}

const mapStateToProps = ({global, router}) => ({
    loading: global.buttonLoading,
    categories: global.categories,
    search: router.location.search
})
const mapDispatchToProps = dispatch => {
    return {
        dispatch
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AddAdvertisement)
